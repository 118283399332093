/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

:export {
  noColor: #fff;
  primaryColor: #06363d;
  secondaryColor: #455575;
  bodyBackgroundColor: F5F5F5;
  primaryBackgroundColor: #F5F5F5;
  secondaryBackgroundColor: #C0C0C0;
  fontColor: #303030;
  linkColor: #337ab7;
  linkColorHover: #23527c;
  headerBackgroundColor: #06363d;
  headerBackgroundColorGradient: #455575;
  headerForeColor: #F5F5F5;
  mainBackgroundColor: #F5F5F5;
  mainForeColor: #06363d;
  footerBackgroundColor: #06363d;
  footerBackgroundColorGradient: #455575;
  footerForeColor: #F5F5F5; }

/* Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%; }

/* Apply a natural box layout model to all elements */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

/* ---------------------- BODY ---------------------- */
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  background-color: #F5F5F5; }

div#root {
  height: 100%;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-rows: 100px 1fr 400px;
  -ms-grid-rows: 100px 1fr 300px;
  grid-template-areas: "header" "main" "footer"; }

/* ---------------------- HEADER ---------------------- */
header {
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin); }
  header .header__elements {
    display: flex;
    flex-direction: row;
    align-items: center; }
    header .header__elements .logo {
      margin-left: 5px;
      width: 80px;
      height: 80px; }

.theme-changer {
  position: absolute;
  top: 0;
  right: 0;
  width: auto; }

/* ---------------------- NAVIGATION ---------------------- */
/* ---------------------- MAIN ---------------------- */
main {
  -ms-grid-column: 1;
  -ms-grid-row: 2;
  grid-area: main;
  display: block;
  margin: 10px 0 10px 0;
  padding: 0 20px 0 20px;
  justify-self: center;
  width: 100%; }
  main a {
    text-decoration: none;
    color: #337ab7; }
  main a:hover {
    color: #23527c; }
  main > div:first-child {
    height: 100%; }
  main article section {
    margin-bottom: 50px; }
  main article section:last-child {
    margin-bottom: 0px; }
  main article h1 {
    font-size: 1.5rem; }
  main article h2 {
    font-size: 1.25rem; }
  main article h3 {
    font-size: 1rem; }
  main article h1, main article h2, main article h3 {
    margin-bottom: 15px; }
    main article h1 a, main article h2 a, main article h3 a {
      color: inherit; }
    main article h1 a:hover, main article h2 a:hover, main article h3 a:hover {
      color: inherit; }
  main article p {
    margin-bottom: 15px; }
  main article ul, main article ol {
    margin-left: 30px;
    margin-bottom: 15px; }
    main article ul li p:first-child, main article ol li p:first-child {
      margin-top: 15px; }
  main article ul {
    list-style-type: disc; }

.main__page-loading-wrapper--true {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background: #F5F5F5;
  opacity: 1; }

.main__page-loading-wrapper--false {
  display: none; }

.main__page-loading-wrapper__page-loading-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .main__page-loading-wrapper__page-loading-container div {
    flex-grow: 0; }
  .main__page-loading-wrapper__page-loading-container .page-loading-title {
    margin-top: 15px; }

/* ---------------------- FOOTER ---------------------- */
footer {
  -ms-grid-column: 1;
  -ms-grid-row: 3;
  grid-area: footer;
  width: 100%;
  bottom: 0;
  padding: 0 0 0 0;
  font-weight: 700; }
  footer .footer-container {
    height: 100%;
    display: -ms-grid;
    display: grid;
    justify-content: center;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "links" "social" "contact" "copyright";
    padding-bottom: 25px; }
    footer .footer-container a {
      text-decoration: none; }
  footer .footer-container__links {
    grid-area: links;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    margin-top: 30px; }
    footer .footer-container__links ul {
      list-style: none; }
      footer .footer-container__links ul li {
        text-align: center;
        padding: 0 0 10px 0; }
      footer .footer-container__links ul li.link--false {
        display: none; }
      footer .footer-container__links ul li.link--true {
        display: block; }
  footer .footer-container__social {
    text-align: center;
    grid-area: social;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    margin-top: 25px; }
    footer .footer-container__social ul {
      list-style: none; }
      footer .footer-container__social ul li {
        display: inline;
        text-align: center;
        padding: 20px; }
    footer .footer-container__social a {
      font-size: 1.5rem; }
    footer .footer-container__social a.social-link-md {
      display: none; }
    footer .footer-container__social a.social-link-sm {
      display: inline; }
  footer .footer-container__contact {
    text-align: center;
    grid-area: contact;
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    margin-top: 10px;
    margin-bottom: 10px; }
    footer .footer-container__contact ul {
      margin-top: 0px;
      list-style: none; }
      footer .footer-container__contact ul li {
        display: inline; }
      footer .footer-container__contact ul li:first-child {
        padding-right: 15px; }
    footer .footer-container__contact p {
      padding: 0 0 10px 0; }
  footer .footer-container__copyright {
    text-align: center;
    grid-area: copyright;
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    width: 90%; }

/* end media query */
@media only screen and (min-width: 60rem) {
  div#root {
    grid-template-rows: 100px 1fr 250px;
    -ms-grid-rows: 100px 1fr 150px; }
  /* ---------------------- HEADER ---------------------- */
  header .header__elements {
    width: 100%;
    max-width: 60rem;
    margin: 0 auto; }
    header .header__elements .logo {
      margin-left: 35px; }
  /* ---------------------- MAIN ---------------------- */
  main {
    max-width: 60rem;
    margin: 0 auto;
    padding: 0 50px 0 50px;
    margin-top: 10px;
    margin-bottom: 10px; }
  /* ---------------------- FOOTER ---------------------- */
  footer .footer-container {
    max-width: 60rem;
    margin: 0 auto;
    height: 100%;
    padding: 0 30px 0 45px;
    display: grid;
    display: -ms-grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 150px 1fr 300px;
    -ms-grid-columns: 150px 1fr 300px;
    grid-template-rows: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-areas: "links social contact" "copyright copyright copyright"; }
  footer .footer-container__links {
    grid-area: links;
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
    footer .footer-container__links ul {
      list-style: none; }
      footer .footer-container__links ul li {
        text-align: left; }
  footer .footer-container__social {
    text-align: center;
    grid-area: social;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    margin-top: 60px; }
    footer .footer-container__social a.social-link-md {
      display: inline; }
    footer .footer-container__social a.social-link-sm {
      display: none; }
  footer .footer-container__contact {
    text-align: left;
    grid-area: contact;
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    margin-top: 30px;
    margin-bottom: 0px; }
  footer .footer-container__copyright {
    margin-top: 50px;
    margin-right: 25px;
    text-align: center;
    grid-area: copyright;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    width: 99%; } }

/* end media query */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main__page-loading-wrapper__page-loading-container {
    top: 50%;
    transform: translateY(-25%); } }
