div#root {
    grid-template-rows: 100px 1fr 250px;
    -ms-grid-rows: 100px 1fr 150px;
}

 /* ---------------------- HEADER ---------------------- */
 header {

    .header__elements {
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;

        .logo {
            margin-left: 35px;
        }
    }
}


/* ---------------------- MAIN ---------------------- */
main {
    max-width: 60rem;
    margin: 0 auto;
    padding: 0 50px 0 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}
 

/* ---------------------- FOOTER ---------------------- */
footer {
    .footer-container {
        max-width: 60rem;
        margin: 0 auto;

        height: 100%;
        padding: 0 30px 0 45px;
        
        display: grid;
        display: -ms-grid;

        justify-content: center;
        justify-items: center;
        
        grid-template-columns: 150px 1fr 300px;
        -ms-grid-columns: 150px 1fr 300px;
                
        grid-template-rows: auto auto;
        -ms-grid-rows: auto auto;
        
        grid-template-areas: 
            "links social contact"
            "copyright copyright copyright";
    }

    .footer-container__links {
        grid-area: links;
        -ms-grid-row:1; 
        -ms-grid-column:1;

        ul {
            list-style: none;

            li {
                text-align: left;
            }
        }
    }

    .footer-container__social {
        text-align: center;
        grid-area: social;
        -ms-grid-row:1; 
        -ms-grid-column:2;

        margin-top: 60px;

        a.social-link-md {
            display: inline;
        }

        a.social-link-sm {
            display: none;
        }
    }

    .footer-container__contact {
        text-align: left;
        grid-area: contact;
        -ms-grid-row:1; 
        -ms-grid-column:3; 
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .footer-container__copyright {
        margin-top: 50px;
        margin-right: 25px;
        text-align: center;
        grid-area: copyright;
        -ms-grid-row:2; 
        -ms-grid-column:1;
        -ms-grid-column-span: 3;
        width: 99%;
    }
}