$noColor: #fff;
$primaryColor: #06363d;
$secondaryColor: #455575;
$bodyBackgroundColor: F5F5F5;
$primaryBackgroundColor: #F5F5F5;
$secondaryBackgroundColor: #C0C0C0;
$fontColor: #303030;
$linkColor: #337ab7;
$linkColorHover: #23527c;

$successColor: #43a047;
$infoColor: #191970;
$errorColor: #d32f2f;
$warningColor: #ffa000;

$headerBackgroundColor: $primaryColor;
$headerBackgroundColorGradient: $secondaryColor;
$headerForeColor: $primaryBackgroundColor;
$mainBackgroundColor: $primaryBackgroundColor;
$mainForeColor: $primaryColor;
$footerBackgroundColor: $headerBackgroundColor;
$footerBackgroundColorGradient: $headerBackgroundColorGradient;
$footerForeColor: $headerForeColor;

$spinnerColorAlt: #61DAFB;
$headerBackgroundColorAlt: lighten($primaryColor, 25%);
$headerBackgroundColorGradientAlt: lighten($primaryColor, 25%);
$headerForeColorAlt: #61DAFB;
$mainBackgroundColorAlt: #61DAFB;
$mainForeColorAlt: #282c34;
$footerBackgroundColorAlt: $headerBackgroundColorAlt;
$footerBackgroundColorGradientAlt: $headerBackgroundColorGradientAlt;
$footerForeColorAlt: $headerForeColorAlt;

:export {
    noColor: $noColor;
    primaryColor: $primaryColor;
    secondaryColor: $secondaryColor;
    bodyBackgroundColor: $bodyBackgroundColor;
    primaryBackgroundColor: $primaryBackgroundColor;
    secondaryBackgroundColor: $secondaryBackgroundColor;
    fontColor: $fontColor;
    linkColor: $linkColor;
    linkColorHover: $linkColorHover;
    
    headerBackgroundColor: $headerBackgroundColor;
    headerBackgroundColorGradient: $headerBackgroundColorGradient;
    headerForeColor: $headerForeColor;
    mainBackgroundColor: $mainBackgroundColor;
    mainForeColor: $mainForeColor;
    footerBackgroundColor: $footerBackgroundColor;
    footerBackgroundColorGradient: $footerBackgroundColorGradient;
    footerForeColor: $footerForeColor;
}

$minWidthForMediumDevices: 35rem;
$minWidthForLargeDevices: 60rem;
$fontSize: 16px;
$fontFamily: 'Roboto', sans-serif;
$normalWeight: 300;
$boldWeight: 700;
$gutter: 2%;
$gutterdbl: (2 * $gutter);