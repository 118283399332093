.ticket-header-wrapper__field-group {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    
    > div { padding: 2px; min-width: 75px; }
}

.init-cap {
    text-transform: capitalize;
}