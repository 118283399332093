@import '../../sass/variables';

.csat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.csat-container--true {
    display: '';
}

.csat-container--false {
    display: none;
}

.csat-container__title {
    font-weight: $boldWeight;
    margin-bottom: 10px;
}

.csat-container__answer-buttons {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 200px;
    justify-content: space-around;
}

.csat-container__comment {
    label {
        display: block;
    }

    button {
        width: 100%;
    }
}

.csat-thank-you {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.csat-thank-you--true {
    font-weight: $boldWeight;
}

.csat-thank-you--false {
    display: none;
}