@import "../../../sass/variables";

@import "small-default";

@media only screen and (min-width: $minWidthForMediumDevices) {
    @import "medium";
} /* end media query */

@media only screen and (min-width: $minWidthForLargeDevices) {
    @import "large";
} /* end media query */