/* Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height:100%;
}

/* Apply a natural box layout model to all elements */
* { 
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
 }


  /* ---------------------- BODY ---------------------- */
body {
    height:100%;
    font-family: $fontFamily;
    font-weight: $normalWeight;
    font-size: $fontSize;
    background-color: $primaryBackgroundColor;
}

div#root {
    height: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: 100px 1fr 400px;
    -ms-grid-rows: 100px 1fr 300px;
	grid-template-areas: 
		"header"
		"main"
		"footer";
}

 /* ---------------------- HEADER ---------------------- */
header {
    -ms-grid-column:1;
    -ms-grid-row:1; 
    grid-area: header;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: calc(10px + 2vmin);

    .header__elements {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
            margin-left: 5px;
            width: 80px;
            height: 80px;
        }
    }
    
}

.theme-changer {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
}

 /* ---------------------- NAVIGATION ---------------------- */



/* ---------------------- MAIN ---------------------- */
main {
    -ms-grid-column:1;
    -ms-grid-row:2; 
    grid-area: main;
    display: block;
    margin: 10px 0 10px 0;
    padding: 0 20px 0 20px;
    justify-self: center;
    width: 100%;

    a {
        text-decoration: none;
        color: $linkColor;
    }

    a:hover {
        color: $linkColorHover;
    }

    > div:first-child {
        height: 100%;
    }

    article {

        section {
            margin-bottom: 50px;
        }

        section:last-child {
            margin-bottom: 0px;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        h3 {
            font-size: 1rem;
        }

        h1, h2, h3 {
            margin-bottom: 15px;
            a {
                color: inherit;
            }

            a:hover {
                color: inherit;
            }
        }

        p {
            margin-bottom: 15px;
        }

        ul, ol {
            margin-left: 30px;
            margin-bottom: 15px;

            li {
                p:first-child {
                    margin-top: 15px;
                }
            }
        }

        ul {
            list-style-type: disc;
        }
    }
}

.main__page-loading-wrapper--true {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    background: $primaryBackgroundColor;
    opacity: 1;
}

.main__page-loading-wrapper--false {
    display: none;
}

.main__page-loading-wrapper__page-loading-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        flex-grow: 0;
    }

    .page-loading-title {
        margin-top: 15px;
    }
}

/* ---------------------- FOOTER ---------------------- */
footer {
    -ms-grid-column:1;
    -ms-grid-row:3; 
    grid-area: footer;
    width: 100%;
    bottom: 0;
    padding: 0 0 0 0;
    font-weight: $boldWeight;

    .footer-container {
        height: 100%;

        display: -ms-grid;
        display: grid;

        justify-content: center;
        justify-items: center;

        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        
        -ms-grid-rows: auto auto auto auto;
        grid-template-rows: auto auto auto auto;
        
        grid-template-areas: 
            "links"
            "social"
            "contact"
            "copyright";

        a {
            text-decoration: none;
        }

        padding-bottom: 25px;
    }

    .footer-container__links {
        grid-area: links;
        -ms-grid-row:1; 
        -ms-grid-column:1; 

        margin-top: 30px;

        ul {
            list-style: none;

            li {
                text-align: center;
                padding: 0 0 10px 0;
            }

            li.link--false {
                display: none;
            }

            li.link--true {
                display: block;
            }
        }
    }

    .footer-container__social {
        text-align: center;
        grid-area: social;
        -ms-grid-row:2; 
        -ms-grid-column:1;

        margin-top: 25px;

        ul {
            list-style: none;

            li {
                display: inline;
                text-align: center;
                padding: 20px;
            }
        }

        a {
            font-size: 1.5rem;
        }

        a.social-link-md {
            display: none;
        }

        a.social-link-sm {
            display: inline;
        }
    }

    .footer-container__contact {
        text-align: center;
        grid-area: contact;
        -ms-grid-row:3; 
        -ms-grid-column:1; 
        margin-top: 10px;
        margin-bottom: 10px;

        ul {
            margin-top: 0px;
            list-style: none;
            li {
                display: inline;
            }

            li:first-child {
                padding-right: 15px;
            }
        }

        p {
            padding: 0 0 10px 0;
        }

        // ul {
        //     margin-top: 0px;
        // }
    }

    .footer-container__copyright {
        text-align: center;
        grid-area: copyright;
        -ms-grid-row:4; 
        -ms-grid-column:1;
        width: 90%;
    }
}