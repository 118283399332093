.ticket-action-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    > div {
        width: 100%;
        padding: 15px;

        button {
            width: 100%;
            font-size: 0.60rem;
        }
    }
}