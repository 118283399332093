.form-container__action-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;

    > div {
        width: 100%;
    }

    > div:first-child {
        padding-right: 15px;
    }

    > div:last-child {
        padding-left: 15px;
    }

    button {
        width: 100%;
    }
}