.ticket-history-container {
    margin: 25px;
    padding: 15px;
    position: relative;
    border-radius: .5em;
    border-top-style: solid;
    border-top-width: 0.25em;
    border-right-style: solid;
    border-right-width: 0.25em;
    border-bottom-style: solid;
    border-bottom-width: 0.25em;
    border-left-style: solid;
    border-left-width: 0.25em;
    word-break: break-word;
}

.ticket-history-container--agent {
    margin-left: 15px;
    margin-right: 100px;
}

.ticket-history-container--agent:after {
    content: '';
	position: absolute;
	bottom: 0;
	left: 5px;
	width: 0;
	height: 0;
	border: 13px solid transparent;
	border-top-color: inherit;
	border-bottom: 0;
	border-left: 0;
	margin-left: -6.5px;
	margin-bottom: -13px;
}


.ticket-history-container--customer {
    margin-left: 100px;
    margin-right: 15px;
}

.ticket-history-container--customer:after {
    content: '';
	position: absolute;
	bottom: 0;
	right: -1px;
	width: 0;
	height: 0;
	border: 13px solid transparent;
	border-top-color: inherit;
	border-bottom: 0;
	border-right: 0;
	margin-left: -6.5px;
	margin-bottom: -13px;
}

.ticket-history-container__title {
    font-weight: $boldWeight;
}

.ticket-history-container__sub-title {
    margin-top: 5px;
    font-style: italic;
}

.ticket-history-container__body {
    margin-top: 5px;
}